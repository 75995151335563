import { Component, HostBinding, Input } from '@angular/core'

@Component({
  selector: 'rmd-loader',
  template: '<div></div>',
  styleUrls: ['./rmd-loader.component.scss'],
})
export class RmdLoaderComponent {
  @Input() withOverlay = false

  @HostBinding('class.with-overlay') get withOverlayClass() {
    return this.withOverlay
  }
}
